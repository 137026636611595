<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <div>
              <b-form @reset="resetForm">
                <div class="row">
                  <b-form-group
                    label="Usuario:"
                    v-if="isEdit === false"
                    class="col-md-6"
                  >
                    <b-form-input
                      v-model="form.username"
                      type="text"
                      required
                      ref="username"
                      @keyup.enter="$refs.email.select"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Correo:" class="col-md-6">
                    <b-form-input
                      v-model="form.email"
                      type="email"
                      required
                      ref="email"
                      @keyup.enter="$refs.first_name.select"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Nombre:" class="col-md-6">
                    <b-form-input
                      v-model="form.first_name"
                      type="text"
                      required
                      ref="first_name"
                      @keyup.enter="$refs.last_name.select"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Primer apellido:" class="col-md-6">
                    <b-form-input
                      v-model="form.last_name"
                      type="text"
                      required
                      ref="last_name"
                      @keyup.enter="$refs.last_name_2.select"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Segundo apellido:" class="col-md-6">
                    <b-form-input
                      v-model="form.last_name_2"
                      type="text"
                      required
                      ref="last_name_2"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Contraseña:"
                    v-if="isEdit === false"
                    class="col-md-6"
                  >
                    <b-form-input
                      v-model="form.password"
                      type="password"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    class="col-md-6"
                    label="Confirme su contraseña:"
                    v-if="isEdit === false"
                  >
                    <b-form-input
                      v-model="form.password2"
                      type="password"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <b-form-group label="Grupos">
                  <multiselect
                    v-model="form.profiles"
                    :options="profiles"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Elije el rol"
                    label="name"
                    track-by="id"
                    :preselect-first="true"
                  >
                  </multiselect>
                </b-form-group>

                <b-button variant="primary" class="mr-2" @click="submit"
                  >Guardar</b-button
                >
                <b-button type="reset" variant="danger" v-if="isEdit === false"
                  >Limpiar</b-button
                >
              </b-form>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "../../../core/services/api.service";

export default {
  name: "UserCreate",
  data() {
    return {
      form: { profiles: [] },
      isEdit: false,
      action: "Crear",
      profiles: [],
    };
  },
  components: {},
  mounted() {
    this.getUserIfEdit();
    this.getUsers();
    this.getProfiles();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Usuarios", route: "/core/user" },
      { title: `${this.action} Usuario` },
    ]);
  },
  methods: {
    getProfiles() {
      ApiService.get("core/profile").then(({ data }) => {
        console.log(data);
        this.profiles = data;
      });
    },
    getUserIfEdit() {
      console.log(this.$route);
      if ("id" in this.$route.params) {
        console.log("edit");
        this.isEdit = true;
        this.action = "Editar";
        ApiService.get(`core/user/${this.$route.params.id}`).then((res) => {
          this.form = res.data;
        });
      }
    },
    resetForm() {
      this.form = {};
    },
    submit() {
      let method = this.isEdit === true ? ApiService.put : ApiService.post;
      let url =
        this.isEdit === true ? `core/user/${this.form.id}/` : "register/";

      method(url, this.form)
        .then(() => {
          this.$successToast();
          if (this.isEdit === false) {
            this.form = {};
          }
        })
        .catch((err) => {
          console.log(err);
          this.$errorToast(err);
        });
    },
    getUsers() {
      ApiService.get("user").then((res) => {
        console.log(res);
        this.items = res.data.results;
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
